/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import { CheckIcon, RefreshCcwIcon } from 'lucide-react';
import { KPI } from 'types/kpi-data';

import { cn } from '@/lib/utils';

import { OptimizationIcons } from '../icons';
import { Skeleton } from '../ui/skeleton';
import { WorkflowDetails } from './workflow-details';

export const KPIItems = ({
  appName,
  sla,
}: {
  appName: string;
  sla: string;
}) => {
  const [data, setData] = useState<{ [key: string]: KPI }>();
  const [loading, setLoading] = useState(true);
  const { messages } = useLanguage();

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const KPIButtonsData = [
    {
      id: 0,
      label: messages?.ApplicationDetailPage?.last_7_days,
      value: '7',
    },
    {
      id: 1,
      label: messages?.ApplicationDetailPage?.last_30_days,
      value: '30',
    },
    {
      id: 2,
      label: messages?.ApplicationDetailPage?.all_time,
      value: 'all',
    },
  ];

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const fetchKPI = async (days: string = KPIButtonsData[0].value) => {
    try {
      setLoading(true);
      const daysAgo = (n: number) => {
        const d = new Date();
        d.setDate(d.getDate() - Math.abs(n));
        return d;
      };

      let queryParams = {};
      if (days !== '' && days !== 'all') {
        const end_time = new Date();
        const start_time = daysAgo(parseInt(days));
        queryParams = {
          start_time: start_time.toISOString(),
          end_time: end_time.toISOString(),
        };
      }
      // else if block is temporary https://github.com/okahu/portal-ux/issues/147
      else if (days === 'all') {
        const end_time = new Date();
        const start_time = '2024-01-01T00:00:00Z';
        queryParams = {
          start_time,
          end_time: end_time.toISOString(),
        };
      }

      const data = await request.get({
        endpoint: `insights/apps/${appName}`,
        queryParams,
      });
      setData(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshKPIData = () => {
    fetchKPI(searchParams.get('days')!.toString());
  };

  useEffect(() => {
    if (searchParams.get('days') === null) {
      router.replace(`${pathname}?${createQueryString('days', '7')}`);
      return;
    }
    const days = searchParams.get('days')!;
    fetchKPI(days);
  }, [searchParams]);

  const KPIData = data ? data[appName] : undefined;

  const handleTraceClick = () => {
    const currentPath = window.location.pathname;
    const currentParams = window.location.search;
    const newPath = `${currentPath}/traces`;
    const newUrl = `${newPath}${currentParams}&workflow=all`;
    router.push(newUrl);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h2 className="text-h2 text-body-primary">
            {messages?.ApplicationDetailPage?.application_insights}
          </h2>

          <button onClick={refreshKPIData}>
            <RefreshCcwIcon className="size-4 text-[#257C9D]" />
          </button>
        </div>
      </div>
      {loading ? (
        <div className="space-y-[14px] pt-8">
          <Skeleton className="animate-pulse-custom h-2.5 w-[664px] rounded-full bg-[#8C9EA1]" />
          <Skeleton className="animate-pulse-custom h-2.5 w-[828px] rounded-full bg-[#8C9EA1]" />
          <Skeleton className="animate-pulse-custom h-2.5 w-[734px] rounded-full bg-[#8C9EA1]" />
        </div>
      ) : (
        <div className="pt-4">
          <div className="flex gap-12">
            <div className="space-y-1">
              {KPIButtonsData.map((btn, id) => (
                <Link
                  key={id}
                  href={`${pathname}?${createQueryString('days', btn.value)}`}
                  className={cn(
                    'flex items-center gap-x-1 text-body',
                    searchParams.get('days') === btn.value
                      ? 'text-body-primary'
                      : 'text-link-active'
                  )}
                  onClick={() => fetchKPI(btn.value)}
                  scroll={false}
                >
                  <span>{btn.label}</span>
                  {searchParams.get('days') === btn.value ? (
                    <CheckIcon size={16} />
                  ) : null}
                </Link>
              ))}
            </div>
            <div className="border-l border-line-secondary pl-[9px]">
              <p className="text-body text-body-primary">
                {messages?.General?.traces}
              </p>
              <p
                className="cursor-pointer pt-[9px] text-kpi-value text-link-active"
                onClick={handleTraceClick}
              >
                {KPIData?.total_request_count ?? 0}
              </p>
            </div>
            {/* <div className="border-l border-line-secondary pl-[9px]">
              <p className="text-body text-body-primary">Avg. Duration</p>
              <p className="pt-[9px] text-kpi-value text-link-active">3.8s</p>
            </div> */}
            <div className="border-l border-line-secondary pl-[9px]">
              <p className="text-body text-body-primary">
                {messages?.ApplicationDetailPage?.status}
              </p>
              <p className="flex items-center gap-1 pt-[9px] text-sm font-normal text-body-subtle">
                {OptimizationIcons.CheckCircleOutline()}
                {messages?.General?.active}
              </p>
            </div>
            {/* Avg latency and Requests for workflows */}
            <WorkflowDetails data={KPIData} sla={sla} />
          </div>
        </div>
      )}
    </div>
  );
};
