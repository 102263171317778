/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import { AppGraph } from 'types/api-data';
import { IAppData, IComponentData } from 'types/new-app-d';

export const useAppDetails = (appName: string) => {
  const [data, setData] = useState<AppGraph>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [addedComponent, setAddedComponent] = useState<IComponentData[]>([]);
  const [appData, setAppData] = useState<IAppData>({
    components: [],
    description: '',
    display_name: '',
    name: '',
    owner: '',
    sla: '',
  });
  const { messages } = useLanguage();
  const langData = messages?.Error;

  const fetchAppDetails = async () => {
    try {
      const queryParams = {
        view: 'graph',
        fetch: 'infra',
      };

      const data = await request.get({
        endpoint: `apps/${appName}`,
        queryParams,
      });
      setData(data[0]);
    } catch (error) {
      setError(`${langData?.error_message}: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const queryParams = {
      view: 'graph',
      fetch: 'infra',
    };
    request
      .get({ endpoint: `apps/${appName}`, queryParams })
      .then((res) => {
        setData(res[0]);
        return res[0];
      })
      .then((res) => {
        request.get({ endpoint: `apps/${res.name}` }).then((res) => {
          setAppData(res[0]);
          setAddedComponent(res[0].components);
        });
      })
      .catch((err) => setError(`${langData?.error_message}: ${err}`));
    setLoading(false);
  }, [appName]);

  return {
    data,
    error,
    loading,
    addedComponent,
    setAddedComponent,
    appData,
    setAppData,
    fetchAppDetails,
  };
};
